import React from 'react'

const DocumentContainer = ({ containerName, emptyMessage, links }: { containerName: string, emptyMessage: string, links: { name: string, url: string}[] }) => {
  return (
    links.length > 0 ?
    (<ul className="investor-links">
      {links.map((link) => (
        <li key={link.url}>
          <a className="download-file" key={link.name} href={link.url} target="_blank">
            <i className="bi bi-file-earmark-pdf"></i>
          </a>
          {link.name}
        </li>
      ))}
    </ul>) :
    (emptyMessage || `No data in ' + ${containerName}`)
  )
}

export default DocumentContainer;