import React from 'react'
import DocumentContainer from '../components/document-container'
import Layout from '../components/layout'

const InvestorsCornerPage = () => {
  const investors = [
    {
      name: 'Financials',
      id: 'financials',
      data: [
        {
          name: 'Annual Financials',
          id: 'annualFinancials',
          data: [
            {
              name: 'Financial-Results-MRC',
              url: '/reports/annual-financials/financial-results-mrc-agrotech.pdf'
            },
          ],
        },
        {
          name: 'Quarterly Financials',
          id: 'quarterlyFinancials',
          data: [
            {
              name: 'Financial statements for quarter ended on 30 June 2023',
              url: '/reports/quarterly-financials/financial-statements-for-quarter-ended-on-30-june-2023.pdf'
            },
            {
              name: 'Financial Results for Quarter Ended on 31 December 2022',
              url: '/reports/quarterly-financials/financial-results-for-quarter-ended-on-31-12-2022.pdf'
            }
          ]
        },
        {
          name: 'Annual Reports',
          id: 'annualReports',
          data: [
            {
              name: 'ANNUAL REPORT 2022',
              url: '/reports/annual-report-2022.pdf',
            },
            {
              name: 'ANNUAL REPORT 2021',
              url: '/reports/annual-report-2021.pdf',
            },
            {
              name: 'ANNUAL REPORT 2020',
              url: '/reports/annual-report-2020.pdf',
            },
            {
              name: 'ANNUAL REPORT 2019',
              url: '/reports/annual-report-2019.pdf',
            },
            {
              name: 'ANNUAL REPORT 2018',
              url: '/reports/annual-report-2018.pdf',
            },
          ]
        },
        {
          name: 'Financials of Subsidiary',
          id: 'financialsOfSubsidiary',
          data: [],
          emptyMessage: 'There are no subsidiaries.',
        },
        {
          name: 'RPT Disclosure',
          id: 'rptDisclosure',
          data: [],
          emptyMessage: 'The company has not entered into any related party transactions',
        }
      ]
    },
    {
      name: 'Compliance',
      id: 'compliance',
      data: [
        {
          name: 'Annual Secretarial compliance Certificate',
          url: '/reports/annual-financials/annual-secretarial-compliance-certificate.pdf',
        },
      ],
    },
    {
      name: 'Shareholder\'s Information',
      id: 'shareholdersInformation',
      data: [],
    },
    {
      name: 'Filing With Regulatory',
      id: 'filingWithRegulatory',
      data: [],
    },
    {
      name: 'Corporate Governance',
      id: 'corporateGovernance',
      data: [
        {
          name: 'Dividend Distribution Policy',
          url: '/reports/corporate-governance/dividend-distribution-policy.pdf',
        },
        {
          name: 'NRC Policy 2021',
          url: '/reports/corporate-governance/nrc-policy-2021.pdf',
        },
        {
          name: 'Code of Conduct',
          url: '/reports/corporate-governance/code-of-conduct-reg17.pdf',
        },
      ],
    }
  ]

  const getInvestorData = (investor: {
    name: string,
    id: string,
    data: {
      name: string;
      url: string;
    }[]
  }) => {
    return investor.data.length > 0 ? (
      <div className="row">
        <div className="col-12">
          <DocumentContainer links={investor.data} containerName={investor.name} emptyMessage={investor.emptyMessage} />
        </div>
      </div>
    ) : (investor.name + ' has no data!')
  }

  return (
    <Layout pageTitle="Investors Corner" title="MRC Agrotech Ltd - Investors Corner" noContainer={true}>
      <div className="investors-tab row">
        <div className="col-12">
          <ul id="nav-tab" className="investors-tab-list nav nav-pills" role="tablist">
            {investors.map((investor, index) => (
              <li key={investor.id}>
                <button className={'nav-link ' + (index===0 ? 'active' : '')} id={investor.id + 'tab'} data-bs-toggle="tab" data-bs-target={'#' + investor.id} type="button" role="tab" aria-controls={investor.id} aria-selected="true">{investor.name}</button>
              </li>
            ))}
          </ul>
        </div>
        <div className="col-12 mt-5">
          <div className="container">
            <div className="tab-content" id="nav-tabContent">
              {investors.map((investor, index) => (
                <div className={'tab-pane fade' + (index ===0 ? ' show active' : '')} key={investor.id + '_content'} id={investor.id} role="tabpanel" aria-labelledby={investor.id + 'tab'} tabIndex={0}>
                  {(investor.data.length > 0 && investor.data[0].id) ? (
                    <div className="row">
                      <div className="col-12 col-lg-4 mb-3">
                        <div className="tab-list-vertical nav nav-pills" role="tablist">
                          {investor.data.map((item, indexBtn) => (
                            <button className={'nav-link text-start ' + (indexBtn===0 ? 'active' : '')} key={investor.id + '_tab_' + item.id} id={investor.id + '_tab_' + item.id} data-bs-toggle="tab" data-bs-target={'#' + investor.id + '_' + item.id} type="button" role="tab" aria-controls={investor.id + '_' + item.id} aria-selected="true">{item.name}</button>
                          ))}
                        </div>
                      </div>
                      <div className="col-12 col-lg-8">
                        <div className="tab-content" id={'nav-tabContent-child-' + investor.id}>
                          {investor.data.map((item, indexContent) => (
                            <div className={'tab-pane fade' + (indexContent ===0 ? ' show active' : '')} key={investor.id + '_' + item.id} id={investor.id + '_' + item.id} role="tabpanel" aria-labelledby={investor.id + '_tab_' + item.id} tabIndex={0}>
                              <DocumentContainer links={item.data} containerName={item.name} emptyMessage={item.emptyMessage} />
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : getInvestorData(investor)}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default InvestorsCornerPage
